<template>
  <div>
    <!--  Page Header  -->
    <base-page-header :title="title" back-link="brands" />

    <!-- Page Breadcrumbs -->
    <base-breadcrumbs :crumbs="breadcrumbs" />

    <!-- Page Content -->
    <div class="page-content">
      <CMC v-if="activeTab == 'cmc'" />
      <Irefi v-else-if="activeTab == 'irefi'" />
      <ClearRealty v-else-if="activeTab == 'clear-realty'" />
      <Vision v-else-if="activeTab == 'vision'" />
      <BlackInk v-else />
    </div>
  </div>
</template>

<script>
import CMC from "./includes/CMC";
import Irefi from "./includes/Irefi";
import Vision from "./includes/Vision";
import ClearRealty from "./includes/ClearRealty";
import BlackInk from "./includes/BlackInk";
import BaseBreadcrumbs from "../../../components/BaseBreadcrumbs";
import BasePageHeader from "../../../components/BasePageHeader";

export default {
  name: "SingleBrands",
  components: {BasePageHeader, BaseBreadcrumbs, CMC, Irefi, Vision, ClearRealty, BlackInk },
  data() {
    return {
      activeTab: this.$route.params.name.toLowerCase(),
    };
  },
  computed: {
    breadcrumbs() {
      let crumbs = [
        {id: 0, title: 'Resource Center', link: 'resource_center'},
        {id: 1, title: 'Brands', link: 'brands'}
      ];
      if (this.activeTab === 'cmc') {
        crumbs.push({id: 2, title: 'Clear Mortgage Capital, Inc.'})
      } else if (this.activeTab === 'irefi') {
        crumbs.push({id: 2, title: 'iRefic.'})
      } else if (this.activeTab === 'clear-realty') {
        crumbs.push({id: 2, title: 'Clear Realty'})
      } else if (this.activeTab === 'vision') {
        crumbs.push({id: 2, title: 'Vision'})
      } else {
        crumbs.push({id: 2, title: 'Black Ink Financial'})
      }
      return crumbs
    },
    title() {
      if (this.activeTab === 'cmc') {
        return 'Clear Mortgage Capital, Inc.'
      } else if (this.activeTab === 'irefi') {
        return 'Irefi'
      } else if (this.activeTab === 'clear-realty') {
        return 'Clear Realty'
      } else if (this.activeTab === 'vision') {
        return 'Vision'
      } else {
        return 'Black Ink Financial'
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page-content {
  padding: 0 !important;
  &-breadcrumbs {
    padding: 11px 24px;
    border-bottom: 1px solid #e6e6e6;

    a,
    span {
      display: inline-block;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
    }

    a {
      color: #4d4d4d;
      text-decoration: none;
    }

    span {
      margin-left: 9px;
      color: #006bf6;

      &.delimeter {
        color: #4d4d4d;
      }
    }
  }
}
::v-deep {
  .page-content {
    &-left {
      min-height: 100vh !important;
      border-right: 1px solid #e6e6e6 !important;
      padding: 0 30px 0 36px;
      .title {
        color: #000000;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 21px;
      }

      .rectangle {
        &:hover {
          div {
            border: 1px solid #017aff;
            background-color: rgba(1, 122, 255, 0.08);
          }
        }

        span {
          color: #000000;
          font-family: Poppins;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 18px;
        }

        div {
          height: 72px;
          border-radius: 4px;
          margin-top: 0.5rem;
          border: 1px solid #dadada;
          cursor: pointer;
          display: flex;
          align-items: center;
          padding-left: 15px;
          background: url("~@/assets/images/pattern-diagonal-lines.png");
          transition: all .3s;
          img {
            max-height: 50px;
          }
        }

        .text-blue {
          color: #065ae4;
        }

        .active-block {
          border: 1px solid #017aff;
          background-color: rgba(1, 122, 255, 0.08);
          background-blend-mode: color-burn;
        }
      }
    }

    &-right {
      .brands-block {
        border-bottom: 1px solid #e6e6e6 !important;
        padding-bottom: 20px;
        &-title {
          color: #000000;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 18px;
        }

        &-colors {
          &-color {
            display: flex;
            align-items: center;
            padding: 6px 30px;
            transition: all .3s;

            &:hover {
              background-color: rgba(0,0,0, .05);
            }

            .color-1,
            .color-2,
            .color-3 {
              height: 24px;
              width: 24px;
              border: 1px solid #dadada;
            }

            .color-1 {
              background-color: #11bfda;
            }

            .color-2 {
              background-color: #0099c1;
            }

            .color-3 {
              background-color: #007090;
            }

            .color-hex {
              color: #000000;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 18px;
              margin-left: 12px;
              cursor: pointer;

              &:hover {
                &:after {
                  width: 100%;
                }
              }
              &:after {
                display: block;
                content: '';
                width: 0;
                height: 1px;
                background: black;
                transition: all .3s;
              }

            }
          }
        }

        &-fonts {
          display: flex;
          align-items: center;
          padding: 6px 25px;
          transition: all .3s;

          &:hover {
            background-color: rgba(0,0,0, .05);
          }

          .font {
            height: 24px;
            width: 24px;
            font-weight: 520;
            text-align: center;
            border-bottom: 1px solid #979797;
          }

          .value {
            color: #000000;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;
            margin-left: 12px;
            cursor: pointer;
            &:hover {
              &:after {
                width: 100%;
              }
            }

            &:after {
              display: block;
              content: '';
              width: 0;
              height: 1px;
              background: black;
              transition: all .3s;
            }
          }
        }

        .preview {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 120px;
          min-width: 330px;
          border: 1px solid #dadada;
          border-radius: 4px;
          overflow: auto;
          padding: 1rem;
          background: url("~@/assets/images/pattern-diagonal-lines.png");

          img {
            margin: 0 auto;
            display: block;
            max-width: 100%;
            max-height: 200px;
          }
        }

        .form-group {
          width: 96px;
        }

        .input-group {
          width: 130px;
          margin-left: 0.4rem;
          input {
            &.form-control {
              background-color: #ffffff;
              padding: 8px;
              border-width: 1px 0 1px 1px;
              height: 36px;
              min-height: 36px;
              color: #000000;
              font-size: 14px;
              letter-spacing: 0;
              line-height: 18px;

              &:not(.prepend) {
                border-radius: 4px 0px 0px 4px;
                border-color: #b4b4b4;
              }
            }
          }
          &-text {
            background-color: #fff;
            border-left: 0;
            height: 100%;
            border-radius: 0px 4px 4px 0px;
            border-color: #b4b4b4;
            color: rgba(0, 0, 0, 0.6);
            font-family: Poppins;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;
          }
        }
        .multiselect {
          min-height: 36px;
          height: 36px;
          &__tags {
            min-height: 36px;
            height: 36px;
            border-radius: 4px;
            padding: 8px 40px 0 8px;
            border: 1px solid #b4b4b4;
          }
          &__select {
            min-height: 36px;
            height: 36px;

            &::before {
              top: 10%;
              border-color: #000000;
            }
          }

          &__single {
            color: #000000;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 18px;
          }
        }
        label {
          opacity: 0.7;
          color: #000000;
          font-family: Poppins;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 12px;
          margin-bottom: 8px;
        }
      }

      .list-group {
        border-bottom: 1px solid #e6e6e6;
        padding-bottom: 1rem;
        &-item {
          border: 0;
          color: #000000;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 18px;

          span {
            opacity: 0.6;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
