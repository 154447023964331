<template>
  <div>
    <PageSpinner v-if="ui.loading" />
    <div class="row w-100 m-auto" v-else>
      <div class="col-md-8 page-content-left">
        <div class="title mt-3">
          Logos
        </div>
        <div class="row">
          <div class="col-6 mt-3 rectangle">
            <span
              :class="{
                'text-blue':
                  previewMode.activatedBy == 'primary-dark' &&
                  previewMode.status
              }"
              >Primary—Dark</span
            >
            <div
              :class="{
                'active-block':
                  previewMode.activatedBy == 'primary-dark' &&
                  previewMode.status
              }"
              @click="activatePreviewMode(logos.primaryDark, 'primary-dark')"
            >
              <img
                :src="agentPhoto(logos.primaryDark)"
                alt=""
                id="primary-dark"
              />
            </div>
          </div>
          <div class="col-6 mt-3 rectangle">
            <span
              :class="{
                'text-blue':
                  previewMode.activatedBy == 'primary-light' &&
                  previewMode.status
              }"
              >Primary—Light</span
            >
            <div
              :class="{
                'active-block':
                  previewMode.activatedBy == 'primary-light' &&
                  previewMode.status
              }"
              @click="activatePreviewMode(logos.primaryLight, 'primary-light')"
            >
              <img
                :src="agentPhoto(logos.primaryLight)"
                alt=""
                id="primary-light"
              />
            </div>
          </div>
          <div class="col-6 mt-3 rectangle">
            <span
              :class="{
                'text-blue':
                  previewMode.activatedBy == 'primary-monochrome' &&
                  previewMode.status
              }"
              >Primary—Monochrome</span
            >
            <div
              :class="{
                'active-block':
                  previewMode.activatedBy == 'primary-monochrome' &&
                  previewMode.status
              }"
              @click="
                activatePreviewMode(
                  logos.primaryMonochrome,
                  'primary-monochrome'
                )
              "
            >
              <img
                :src="agentPhoto(logos.primaryMonochrome)"
                alt=""
                id="primary-monochrome"
              />
            </div>
          </div>
          <div class="col-6 mt-3 rectangle">
            <span
              :class="{
                'text-blue':
                  previewMode.activatedBy == 'primary-white' &&
                  previewMode.status
              }"
              >Primary—White</span
            >
            <div
              :class="{
                'active-block':
                  previewMode.activatedBy == 'primary-white' &&
                  previewMode.status
              }"
              @click="activatePreviewMode(logos.primaryWhite, 'primary-white')"
            >
              <img
                :src="agentPhoto(logos.primaryWhite)"
                alt=""
                id="primary-white"
              />
            </div>
          </div>
          <div class="col-3 mt-3 rectangle">
            <span
              :class="{
                'text-blue':
                  previewMode.activatedBy == 'secondary-colored' &&
                  previewMode.status
              }"
              >Secondary—Colored</span
            >
            <div
              :class="{
                'active-block':
                  previewMode.activatedBy == 'secondary-colored' &&
                  previewMode.status
              }"
              @click="
                activatePreviewMode(logos.secondaryColored, 'secondary-colored')
              "
            >
              <img
                :src="agentPhoto(logos.secondaryColored)"
                alt=""
                id="secondary-colored"
              />
            </div>
          </div>
          <div class="col-3 mt-3 rectangle">
            <span
              :class="{
                'text-blue':
                  previewMode.activatedBy == 'secondary-monochrome' &&
                  previewMode.status
              }"
              >Secondary—Monochrome</span
            >
            <div
              :class="{
                'active-block':
                  previewMode.activatedBy == 'secondary-monochrome' &&
                  previewMode.status
              }"
              @click="
                activatePreviewMode(
                  logos.secondaryMonochrome,
                  'secondary-monochrome'
                )
              "
            >
              <img
                :src="agentPhoto(logos.secondaryMonochrome)"
                alt=""
                id="secondary-monochrome"
              />
            </div>
          </div>
          <div class="col-3 mt-3 rectangle">
            <span
              :class="{
                'text-blue':
                  previewMode.activatedBy == 'secondary-white' &&
                  previewMode.status
              }"
              >Secondary—White</span
            >
            <div
              :class="{
                'active-block':
                  previewMode.activatedBy == 'secondary-white' &&
                  previewMode.status
              }"
              @click="
                activatePreviewMode(logos.secondaryWhite, 'secondary-white')
              "
            >
              <img
                :src="agentPhoto(logos.secondaryWhite)"
                alt=""
                id="secondary-white"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 page-content-right p-0">
        <!-- Preview Block -->
        <div v-if="previewMode.status">
          <div class="brands-block p-4">
            <div class="brands-block-title">
              Preview
            </div>
            <div class="mt-3 preview">
              <img
                :src="agentPhoto(previewMode.image)"
                alt=""
                :height="formData.height"
                :width="formData.width"
              />
            </div>
          </div>
          <ul class="list-group">
            <li class="list-group-item bg-grey">
              <span>Name:</span>
              {{ formData.name }}
            </li>
            <li class="list-group-item">
              <span>Size:</span>
              {{ formData.width }}px x {{ formData.height }}px
            </li>
            <li class="list-group-item">
              <span>Format:</span>
              {{ formData.format }}
            </li>
          </ul>
          <div class="brands-block p-4">
            <div class="form-group">
              <label for="fieldFormat">
                Format
              </label>
              <multiselect
                v-model="formData.format"
                :options="allFormates"
                id="fieldFormat"
                class="full-width"
                :close-on-select="true"
                :show-labels="false"
                :allow-empty="false"
                placeholder="Select"
              />
            </div>
            <label class="mt-3">Size</label>
            <div class="d-flex">
              <div class="form-group">
                <multiselect
                  v-model="formData.size"
                  :options="allSizes"
                  class="full-width"
                  :close-on-select="true"
                  :show-labels="false"
                  :allow-empty="false"
                  placeholder="Select"
                  @select="updateSize"
                />
              </div>
              <div class="input-group mb-2">
                <input
                  v-model.number="formData.height"
                  :class="{ 'input-filled': formData.height.length }"
                  class="form-control"
                  type="number"
                  placeholder="height"
                  :readonly="formData.size != 'Height'"
                  @input="increaseHeight"
                />
                <div class="input-group-append">
                  <div class="input-group-text">PX</div>
                </div>
              </div>
            </div>

            <base-button :title="downloadButtonTitle" action="secondary-default"
                         @click-btn="downloadResource" class="mt-3 w-100" type="button" />
          </div>
        </div>

        <!-- Brands Blocka -->
        <div class="brands-block" v-else>
          <div class="brands-block-title  ps-4 pt-4">
            Color Pallets
          </div>
          <div class="brands-block-colors mt-3">
            <div class="brands-block-colors-color">
              <div class="color"></div>
              <div
                class="color-hex"
                :content="ui.copyClicked ? 'Copied!' : 'Copy'"
                v-tippy="{ arrow: true, placement: 'top-center' }"
                @click="copyToClipboard"
              >
                #1E3EFF
              </div>
            </div>
          </div>
        </div>
        <div class="brands-block" v-if="!previewMode.status">
          <div class="brands-block-title ps-4 pt-4">
            Fonts
          </div>
          <div class="brands-block-fonts mt-3">
            <div class="font">Aa</div>
            <div
              class="value"
              content="Download"
              v-tippy="{ arrow: true, placement: 'top-center' }"
              @click="downloadFonts('poppins')"
            >
              Poppins
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSpinner from "@/components/pageSpinner";
import Multiselect from "vue-multiselect";
import { getBackendUrl } from "@/utils/backendUrl";
import BaseButton from '../../../../components/BaseButton.vue';

export default {
  name: "Vision",
  components: { PageSpinner, Multiselect, BaseButton },
  data() {
    return {
      ui: {
        loading: false,
        copyClicked: false,
      },
      logos: {
        primaryDark: "",
        primaryLight: "",
        primaryMonochrome: "",
        primaryWhite: "",
        secondaryColored: "",
        secondaryMonochrome: "",
        secondaryWhite: ""
      },
      previewMode: {
        status: false,
        image: "",
        activatedBy: ""
      },
      formData: {
        name: "",
        size: "1X",
        height: 48,
        width: 180,
        format: "SVG"
      },
      allFormates: ["SVG", "PNG", "JPG"],
      allSizes: ["1X", "2X", "3X", "4X", "Height"]
    };
  },
  methods: {
    getResources() {
      this.ui.loading = true;

      this.$http
        .get("/api/v1/brands/vision")
        .then(res => {
          this.logos = res.data;
        })
        .catch(err => {
          this.alertError(err.response.statusText);
        })
        .finally(() => {
          this.ui.loading = false;
        });
    },
    updateSize(e) {
      switch (e) {
        case "2X":
          this.setOriginalImage(
            this.previewMode.image,
            this.previewMode.activatedBy
          );
          this.formData.height = this.formData.height * 2;
          this.formData.width = this.formData.width * 2;
          break;

        case "3X":
          this.setOriginalImage(
            this.previewMode.image,
            this.previewMode.activatedBy
          );
          this.formData.height = this.formData.height * 3;
          this.formData.width = this.formData.width * 3;
          break;

        case "4X":
          this.setOriginalImage(
            this.previewMode.image,
            this.previewMode.activatedBy
          );
          this.formData.height = this.formData.height * 4;
          this.formData.width = this.formData.width * 4;
          break;

        default:
          this.setOriginalImage(
            this.previewMode.image,
            this.previewMode.activatedBy
          );
          break;
      }
    },
    activatePreviewMode(image, activatedBy) {
      if (
        this.previewMode.status &&
        this.previewMode.activatedBy == activatedBy
      ) {
        this.closePreviewMode();
      } else {
        this.previewMode.image = image;
        this.previewMode.activatedBy = activatedBy;

        var fileName = "logo-vision-" + activatedBy;
        fileName = fileName
          .split("-")
          .join(" ")
          .replace(/(^[a-z])|(\s+[a-z])/g, txt => txt.toUpperCase());
        this.formData.name = fileName;
        this.setOriginalImage(image, activatedBy);
        this.previewMode.status = true;
      }
    },
    setOriginalImage(image, id) {
      var img = document.getElementById(id);
      this.formData.size = "1X";
      this.formData.height = img.height;
      this.formData.width = img.width;
    },
    increaseHeight() {
      var img = document.getElementById(this.previewMode.activatedBy);
      let ratio = img.width / img.height;
      this.formData.width = ratio * this.formData.height;
    },
    closePreviewMode() {
      this.previewMode.image = "";
      this.previewMode.activatedBy = "";
      this.previewMode.status = false;
    },
    copyToClipboard(e) {
      this.ui.copyClicked = true;
      let content = document.createElement("input");
      content.setAttribute("type", "text");
      content.setAttribute("value", e.target.innerText);
      document.body.appendChild(content);
      content.select();
      document.execCommand("copy");
      content.remove();

      setTimeout(() => {
        this.ui.copyClicked = false;
      }, 100);
    },
    convertQueryString(obj) {
      return Object.keys(obj)
        .map(function(key) {
          return key + "=" + obj[key];
        })
        .join("&");
    },
    downloadResource() {
      let formData = {
        name: this.formData.name
          .split(" ")
          .join("-")
          .toLowerCase(),
        height: this.formData.height,
        width: this.formData.width,
        size: this.formData.size.toLowerCase(),
        format: this.formData.format.toLowerCase()
      };
      window.location.href =
        getBackendUrl() +
        "/api/v1/brands/resource/download?" +
        this.convertQueryString(formData);
    },
    downloadFonts(name) {
      window.location.href =
        getBackendUrl() + `/api/v1/brands/resource/download/${name}`;
    }
  },
  computed: {
    downloadButtonTitle() {
      return 'Download 1 ' + this.formData.format
    }
  },
  created() {
    this.getResources();
  }
};
</script>

<style lang="scss" scoped>
.page-content {
  &-right {
    .brands-block {
      &-colors {
        &-color {
          display: flex;
          align-items: center;
          .color {
            height: 24px;
            width: 24px;
            border: 1px solid #dadada;
            background-color: #1e3eff;
          }

          .color-hex {
            color: #000000;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;
            margin-left: 12px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
