<template>
  <div class="page-header">
    <div class="row">
      <div class="col-md-3 col-lg-4 my-auto my-lg-none">
        <div class="d-flex align-items-center">
          <router-link :to="{ name: backLink }" v-if="backLink.length">
            <button class="btn btn-control me-2">
              <img alt="Return back" src="@/assets/icons/bold-icon previous.svg" />
            </button>
          </router-link>
          <h1 class="page-header__title">{{ title }}</h1>
          <span class="page-header__subtitle">{{ subtitle }}</span>
        </div>
      </div>
      <div class="col-md-9 col-lg-8">
        <slot name="buttons"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BasePageHeader",
  props: {
    backLink: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
  },
};
</script>
