<template>
    <nav class="page-breadcrumbs">
      <ol class="page-breadcrumbs__list">
        <li class="page-breadcrumbs__item" v-for="(crumb, id) in crumbs" :key="'crumb_' + id">
          <router-link :to="{name: crumb.link}" class="page-breadcrumbs__link" :class="{'page-breadcrumbs__link_current page-breadcrumbs__link_disabled': isLast(id)}">
            {{ crumb.title }}
            <span>/</span>
          </router-link>
        </li>
      </ol>
    </nav>
</template>

<script>
export default {
  name: 'BaseBreadcrumbs',
  props: {
    crumbs: {
      type: Array,
      required: true
    }
  },
  methods: {
    selected(crumb) {
      this.$emit('selected', crumb);
    },
    isLast(index) {
      return index === this.crumbs.length - 1;
    },
  },
}
</script>

<style lang="scss" scoped>
.page-breadcrumbs {
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    display: inline-block;
  }

  &__link{
    color: #4D4D4D;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;

    span {
      display: inline-block;
      margin: 0 9px;
    }

    &_current {
      color: #065AE4;

      span {
        display: none;
      }
    }

    &_disabled {
      pointer-events: none;
      cursor: none;
    }
  }

}
</style>
